.kb_dropdown {
    display: inline-block;
    position: relative;
}

.kb_dropdown__content {
    display: none;
    position: absolute;
    z-index: 1001;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    left: 0;
	/*transform: translateX(-50%);*/
	border-radius: 4px;
	overflow: hidden;
}
.kb_dropdown__content .add-staff-component {
	border-bottom: 1px solid #e8ecf3;
}
.dropdown-xcenter{
  left: 50%;
  transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.kb_dropdown--active .kb_dropdown__content {
    display: block;
}
.kb_dropdown--active .task-status-title{
  border: 1px solid #CCC !important;
}
.kb_dropdown__trigger .task-status-title,.nav-section-action-bar .task-status-title{
  padding: 8px 10px;
  border: 1px solid #E6E6E6;
  color: #354052;
  font-weight: 500;
  border-radius: 4px;
  background: linear-gradient(180deg,#fff,#f2f4f7);
}
.kb_dropdown__trigger .task-status-title:hover,.nav-section-action-bar .task-status-title:hover {
	background: #fcfcfc;
    background: linear-gradient(180deg,#fcfcfc,#eceff3);
}

/**
 * React Tipsy
 * ===========
 */
.Tipsy {
  display: block;
  position: absolute;
  z-index: 107000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.Tipsy.in {
  opacity: 1;
  filter: alpha(opacity=100);
}
.Tipsy.top {
  margin-top: -3px;
  padding: 5px 0;
}
.Tipsy.right {
  margin-left: 3px;
  padding: 0 5px;
}
.Tipsy.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.Tipsy.left {
  margin-left: -3px;
  padding: 0 5px;
}
.Tipsy-inner {
  background-color: #3A3939;
  border-radius: 4px;
  color: #FFFFFF;
  /*max-width: 200px;*/
  padding: 3px 8px;
  text-align: center;
  word-break: break-all;
}
.Tipsy-arrow {
  border-color: transparent;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}
.Tipsy.top .Tipsy-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #3A3939;
}
.Tipsy.right .Tipsy-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #3A3939;
}
.Tipsy.left .Tipsy-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #3A3939;
}
.Tipsy.bottom .Tipsy-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #3A3939;
}

.KB-select_box {
  display: inline-block;
  cursor: pointer; }
  .KB-select_box .kb_dropdown__content {
    background-color: #ffffff; }

.KB-selected_title {
  display: inline-block;
  float: left; }
  .KB-selected_title li {
    margin-right: 10px;
    position: relative; }
    .KB-selected_title li > span {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAXtJREFUOBGVk0tKA0EQhqt61KXmvcwBFDxEFoLnSDaiGYgnmBMYmChuknMILnIIIR4gO/PWpTrT9l90DU1AmDQ0M11/f/XormbaG+vB4CLL85619oqtbUO2zHNmfo2MGdeHw1mIsC5skpwsN5sHt75xsFF7+HVOcrd+btZq95wk39DEAeDFev3i1h0YS4xpq16/hhOJ5COXheG/4xliqTnL3jTt026XomqVPsdjync7ScZUKnTW61G23dLXZCI2lBNF0aXxB1bUDPi43aZKv08AMatxLDZoOhAQLH/E8YysPVcBAOCjZpN+l0sx6/9uNCqyEoH53ehVqQOkjY2AAf4LOwBskbo6OPRr0CQhpDVrZM1EzyTcC9agw0IjTjtqNKQElIKZrVZSCrRwgDVoT99houGqfuZzAXEemNs0FRs0HXKNYGFYxPGju5ZbFct8nYOnVpreySGitx00LQP6PVPPkDhAT0tvO69hOfsOoUlk/w6gF69RNx/6nP8AIFbNN9jJn0oAAAAASUVORK5CYII=) no-repeat;
      background-size: 100% 100%; }
    .KB-selected_title li:hover > span {
      display: block; }

.add-option_btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  border: none;
  font-size: 18px;
  background-color: #2ea1f8; }

.single-selection {
  display: inline-block;
  padding: 8px 10px;
  border: 1px solid #e6e6e6; }

.option-content_list {
  max-height: 240px;
  overflow-y: auto; }
  .option-content_list li {
    padding: 10px 15px;
    border-bottom: 1px solid #e8ecf3; }
    .option-content_list li:last-child {
      border-bottom: none; }
    .option-content_list li:hover {
      background-color: #f2f4f7;
      color: #2ea1f8;
      text-decoration: none; }
  .option-content_list .option-img {
    display: inline-block;
    margin-right: 5px; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.custom-theme:focus {
  outline: 0;
}
.custom-theme.fr-box.fr-basic .fr-element {
  color: #333333;
  padding: 10px;
  overflow-x: auto;
  min-height: 40px;
}
.custom-theme .fr-element {
  -webkit-user-select: auto;
}
.custom-theme.fr-box a.fr-floating-btn {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  height: 32px;
  width: 32px;
  background: #ffffff;
  color: #1e88e5;
  -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  left: 0;
  top: 0;
  line-height: 32px;
  border: none;
}
.custom-theme.fr-box a.fr-floating-btn svg {
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
  fill: #1e88e5;
}
.custom-theme.fr-box a.fr-floating-btn i {
  font-size: 14px;
  line-height: 32px;
}
.custom-theme.fr-box a.fr-floating-btn:hover {
  background: #ebebeb;
}
.custom-theme.fr-box a.fr-floating-btn:hover svg {
  fill: #1e88e5;
}
.custom-theme .fr-wrapper .fr-placeholder {
  font-size: 12px;
  color: #aaaaaa;
  top: 0;
  left: 0;
  right: 0;
}

.fr-wrapper div:first-child {
  display: none!important;
  opacity: 0!important;
}

.fr-box .fr-wrapper .fr-element {
  display: block!important;
  opacity: 1!important;
}

.custom-theme .fr-wrapper ::selection {
  background: #b5d6fd;
  color: #333333;
}
.custom-theme .fr-wrapper ::-moz-selection {
  background: #b5d6fd;
  color: #333333;
}
.custom-theme.fr-box.fr-basic .fr-wrapper {
  background: #ffffff;
  border: 0px;
  border-top: 0;
  top: 0;
  left: 0;
}
.custom-theme.fr-box.fr-basic.fr-top .fr-wrapper {
  border-top: 0;
  border-radius: 0 0 0px 0px;
  -moz-border-radius: 0 0 0px 0px;
  -webkit-border-radius: 0 0 0px 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.custom-theme.fr-box.fr-basic.fr-bottom .fr-wrapper {
  border-bottom: 0;
  border-radius: 0px 0px 0 0;
  -moz-border-radius: 0px 0px 0 0;
  -webkit-border-radius: 0px 0px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
}
.custom-theme .fr-sticky-on.fr-sticky-ios {
  left: 0;
  right: 0;
}
.custom-theme.fr-box .fr-counter {
  color: #cccccc;
  background: #ffffff;
  border-top: solid 1px #ebebeb;
  border-left: solid 1px #ebebeb;
  border-radius: 0px 0 0 0;
  -moz-border-radius: 0px 0 0 0;
  -webkit-border-radius: 0px 0 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.custom-theme.fr-box.fr-rtl .fr-counter {
  right: auto;
  border-right: solid 1px #ebebeb;
  border-radius: 0 0px 0 0;
  -moz-border-radius: 0 0px 0 0;
  -webkit-border-radius: 0 0px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.custom-theme textarea.fr-code {
  background: #ffffff;
  color: #000000;
}
.custom-theme.fr-box.fr-code-view.fr-inline {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.custom-theme.fr-box.fr-inline .fr-command.fr-btn.html-switch {
  top: 0;
  right: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  background: #ffffff;
  color: #999999;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  padding: 12px 12px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
}
.custom-theme.fr-box.fr-inline .fr-command.fr-btn.html-switch i {
  font-size: 14px;
  width: 14px;
}
.custom-theme.fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
  background: #ebebeb;
}
.custom-theme.fr-popup .fr-colors-tabs {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab {
  color: #333333;
  padding: 8px 0;
}
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab:hover,
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab:focus {
  color: #1e88e5;
}
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab[data-param1="background"]::after {
  bottom: 0;
  left: 0;
  background: #1e88e5;
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
}
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab {
  color: #1e88e5;
}
.custom-theme.fr-popup .fr-color-set {
  line-height: 0;
}
.custom-theme.fr-popup .fr-color-set > span > i {
  bottom: 0;
  left: 0;
}
.custom-theme.fr-popup .fr-color-set > span .fr-selected-color {
  color: #ffffff;
  font-weight: 400;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.custom-theme.fr-popup .fr-color-set > span:hover,
.custom-theme.fr-popup .fr-color-set > span:focus {
  outline: 1px solid #333333;
}
.custom-theme .fr-drag-helper {
  background: #1e88e5;
}
.custom-theme.fr-popup .fr-link:focus {
  background: #ebebeb;
}
.custom-theme .fr-file-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
}
.custom-theme .fr-file-upload-layer:hover {
  background: #ebebeb;
}
.custom-theme .fr-file-upload-layer.fr-drop {
  background: #ebebeb;
  border-color: #1e88e5;
}
.custom-theme .fr-file-upload-layer .fr-form {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.custom-theme .fr-file-progress-bar-layer > h3 {
  margin: 10px 0;
}
.custom-theme .fr-file-progress-bar-layer > div.fr-loader {
  background: #bcdbf7;
}
.custom-theme .fr-file-progress-bar-layer > div.fr-loader span {
  background: #1e88e5;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.custom-theme .fr-file-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  top: 0;
}
.custom-theme.fr-box.fr-fullscreen {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tr {
  border: 0;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr {
  border-bottom: solid 1px #ebebeb;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:first-child {
  color: #707070;
}
.custom-theme .fr-image-resizer {
  border: solid 1px #1e88e5;
}
.custom-theme .fr-image-resizer .fr-handler {
  background: #1e88e5;
  border: solid 1px #ffffff;
}
.custom-theme .fr-image-resizer .fr-handler {
  width: 12px;
  height: 12px;
}
.custom-theme .fr-image-resizer .fr-handler.fr-hnw {
  left: -6px;
  top: -6px;
}
.custom-theme .fr-image-resizer .fr-handler.fr-hne {
  right: -6px;
  top: -6px;
}
.custom-theme .fr-image-resizer .fr-handler.fr-hsw {
  left: -6px;
  bottom: -6px;
}
.custom-theme .fr-image-resizer .fr-handler.fr-hse {
  right: -6px;
  bottom: -6px;
}
@media (min-width: 1200px) {
  .custom-theme .fr-image-resizer .fr-handler {
    width: 10px;
    height: 10px;
  }
  .custom-theme .fr-image-resizer .fr-handler.fr-hnw {
    left: -5px;
    top: -5px;
  }
  .custom-theme .fr-image-resizer .fr-handler.fr-hne {
    right: -5px;
    top: -5px;
  }
  .custom-theme .fr-image-resizer .fr-handler.fr-hsw {
    left: -5px;
    bottom: -5px;
  }
  .custom-theme .fr-image-resizer .fr-handler.fr-hse {
    right: -5px;
    bottom: -5px;
  }
}
.custom-theme.fr-image-overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.custom-theme .fr-image-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
}
.custom-theme .fr-image-upload-layer:hover {
  background: #ebebeb;
}
.custom-theme .fr-image-upload-layer.fr-drop {
  background: #ebebeb;
  border-color: #1e88e5;
}
.custom-theme .fr-image-upload-layer .fr-form {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.custom-theme .fr-image-progress-bar-layer > h3 {
  margin: 10px 0;
}
.custom-theme .fr-image-progress-bar-layer > div.fr-loader {
  background: #bcdbf7;
}
.custom-theme .fr-image-progress-bar-layer > div.fr-loader span {
  background: #1e88e5;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.custom-theme .fr-image-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  top: 0;
}
.custom-theme.fr-modal-head .fr-modal-head-line i.fr-modal-more {
  -webkit-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
  -moz-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
  -ms-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
  -o-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
}
.custom-theme.fr-modal-head .fr-modal-head-line i.fr-modal-more.fr-not-available {
  opacity: 0;
  width: 0;
  padding: 12px 0;
}
.custom-theme.fr-modal-head .fr-modal-tags a {
  opacity: 0;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: #1e88e5;
  -webkit-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
}
.custom-theme.fr-modal-head .fr-modal-tags a.fr-selected-tag {
  background: #d6d6d6;
}
.custom-themediv.fr-modal-body .fr-preloader {
  margin: 50px auto;
}
.custom-themediv.fr-modal-body div.fr-image-list {
  padding: 0;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::after {
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  background: #000000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::before {
  color: #ffffff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty {
  background: #cccccc;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty::after {
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container img {
  -webkit-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img,
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
  -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  margin: 0;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
  background: #b8312f;
  color: #ffffff;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
  background: #ffffff;
  color: #1e88e5;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper .fr-modal-head .fr-modal-tags a:hover {
  background: #ebebeb;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper .fr-modal-head .fr-modal-tags a.fr-selected-tag {
  background: #d6d6d6;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img:hover {
  background: #bf4644;
  color: #ffffff;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img:hover {
  background: #ebebeb;
}
.custom-theme .fr-line-breaker {
  border-top: 1px solid #1e88e5;
}
.custom-theme .fr-line-breaker a.fr-floating-btn {
  left: calc(34%);
  top: -16px;
}
.custom-theme .fr-qi-helper {
  padding-left: 10px;
}
.custom-theme .fr-qi-helper a.fr-btn.fr-floating-btn {
  color: #333333;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-special-characters-modal .fr-special-character {
  border: 1px solid #cccccc;
}
.custom-theme .fr-element table td.fr-selected-cell,
.custom-theme .fr-element table th.fr-selected-cell {
  border: 1px double #1e88e5;
}
.custom-theme .fr-table-resizer div {
  border-right: 1px solid #1e88e5;
}
.custom-theme.fr-popup .fr-table-size .fr-select-table-size {
  line-height: 0;
}
.custom-theme.fr-popup .fr-table-size .fr-select-table-size > span {
  padding: 0px 4px 4px 0;
}
.custom-theme.fr-popup .fr-table-size .fr-select-table-size > span > span {
  border: 1px solid #dddddd;
}
.custom-theme.fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
  background: rgba(30, 136, 229, 0.3);
  border: solid 1px #1e88e5;
}
.custom-theme.fr-popup .fr-table-colors {
  line-height: 0;
}
.custom-theme.fr-popup .fr-table-colors > span > i {
  bottom: 0;
  left: 0;
}
.custom-theme.fr-popup .fr-table-colors > span:focus {
  outline: 1px solid #333333;
}
.custom-theme .fr-element .fr-video::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.custom-theme.fr-box .fr-video-resizer {
  border: solid 1px #1e88e5;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler {
  background: #1e88e5;
  border: solid 1px #ffffff;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler {
  width: 12px;
  height: 12px;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hnw {
  left: -6px;
  top: -6px;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hne {
  right: -6px;
  top: -6px;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hsw {
  left: -6px;
  bottom: -6px;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hse {
  right: -6px;
  bottom: -6px;
}
@media (min-width: 1200px) {
  .custom-theme.fr-box .fr-video-resizer .fr-handler {
    width: 10px;
    height: 10px;
  }
  .custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hnw {
    left: -5px;
    top: -5px;
  }
  .custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hne {
    right: -5px;
    top: -5px;
  }
  .custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hsw {
    left: -5px;
    bottom: -5px;
  }
  .custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hse {
    right: -5px;
    bottom: -5px;
  }
}
.custom-theme .fr-video-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
}
.custom-theme .fr-video-upload-layer:hover {
  background: #ebebeb;
}
.custom-theme .fr-video-upload-layer.fr-drop {
  background: #ebebeb;
  border-color: #1e88e5;
}
.custom-theme .fr-video-upload-layer .fr-form {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.custom-theme .fr-video-progress-bar-layer > h3 {
  margin: 10px 0;
}
.custom-theme .fr-video-progress-bar-layer > div.fr-loader {
  background: #bcdbf7;
}
.custom-theme .fr-video-progress-bar-layer > div.fr-loader span {
  background: #1e88e5;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.custom-theme .fr-video-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  top: 0;
}
.custom-theme.fr-video-overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.custom-theme .fr-view strong {
  font-weight: 700;
}
.custom-theme .fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
  background: #f5f5f5;
}
.custom-theme .fr-view table td,
.custom-theme .fr-view table th {
  border: 1px solid #dddddd;
}
.custom-theme .fr-view table th {
  background: #ebebeb;
}
.custom-theme .fr-view[dir="rtl"] blockquote {
  border-right: solid 2px #5e35b1;
  margin-right: 0;
}
.custom-theme .fr-view[dir="rtl"] blockquote blockquote {
  border-color: #00bcd4;
}
.custom-theme .fr-view[dir="rtl"] blockquote blockquote blockquote {
  border-color: #43a047;
}
.custom-theme .fr-view blockquote {
  border-left: solid 2px #5e35b1;
  margin-left: 0;
  color: #5e35b1;
}
.custom-theme .fr-view blockquote blockquote {
  border-color: #00bcd4;
  color: #00bcd4;
}
.custom-theme .fr-view blockquote blockquote blockquote {
  border-color: #43a047;
  color: #43a047;
}
.custom-theme .fr-view span.fr-emoticon {
  line-height: 0;
}
.custom-theme .fr-view span.fr-emoticon.fr-emoticon-img {
  font-size: inherit;
}
.custom-theme .fr-view .fr-text-bordered {
  padding: 10px 0;
}
.custom-theme .fr-tooltip {
  top: 0;
  left: 0;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  background: #222222;
  color: #ffffff;
  font-size: 11px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
}
.custom-theme.fr-toolbar .fr-command.fr-btn,
.custom-theme.fr-popup .fr-command.fr-btn {
  color: #999999;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  margin: 0px 2px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  padding: 0;
  width: 38px;
  height: 38px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn::-moz-focus-inner,
.custom-theme.fr-popup .fr-command.fr-btn::-moz-focus-inner {
  border: 0;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-btn-text,
.custom-theme.fr-popup .fr-command.fr-btn.fr-btn-text {
  width: auto;
}
.custom-theme.fr-toolbar .fr-command.fr-btn i,
.custom-theme.fr-popup .fr-command.fr-btn i {
  font-size: 14px;
  width: 14px;
  margin: 12px 12px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn span,
.custom-theme.fr-popup .fr-command.fr-btn span {
  font-size: 14px;
  line-height: 17px;
  min-width: 38px;
  height: 17px;
  padding: 0 2px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn img,
.custom-theme.fr-popup .fr-command.fr-btn img {
  margin: 12px 12px;
  width: 14px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-active,
.custom-theme.fr-popup .fr-command.fr-btn.fr-active {
  color: #1e88e5;
  background: transparent;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-selection {
  width: auto;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown i,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown i,
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown span,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown span,
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown img,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown img {
  margin-left: 8px;
  margin-right: 16px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active {
  color: #999999;
  background: #d6d6d6;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus {
  background: #d6d6d6 !important;
  color: #999999 !important;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus::after,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus::after {
  border-top-color: #999999 !important;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown::after,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown::after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #999999;
  right: 4px;
  top: 17px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-disabled,
.custom-theme.fr-popup .fr-command.fr-btn.fr-disabled {
  color: #bdbdbd;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-disabled::after,
.custom-theme.fr-popup .fr-command.fr-btn.fr-disabled::after {
  border-top-color: #bdbdbd !important;
}
.custom-theme.fr-toolbar.fr-disabled .fr-btn,
.custom-theme.fr-popup.fr-disabled .fr-btn,
.custom-theme.fr-toolbar.fr-disabled .fr-btn.fr-active,
.custom-theme.fr-popup.fr-disabled .fr-btn.fr-active {
  color: #bdbdbd;
}
.custom-theme.fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after,
.custom-theme.fr-popup.fr-disabled .fr-btn.fr-dropdown::after,
.custom-theme.fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
.custom-theme.fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
  border-top-color: #bdbdbd;
}
.custom-theme.fr-desktop .fr-command:hover,
.custom-theme.fr-desktop .fr-command:focus {
  color: #999999;
  background: #ebebeb;
}
.custom-theme.fr-desktop .fr-command:hover::after,
.custom-theme.fr-desktop .fr-command:focus::after {
  border-top-color: #999999 !important;
}
.custom-theme.fr-desktop .fr-command.fr-selected {
  color: #999999;
  background: #d6d6d6;
}
.custom-theme.fr-desktop .fr-command.fr-active:hover,
.custom-theme.fr-desktop .fr-command.fr-active:focus {
  color: #1e88e5;
  background: #ebebeb;
}
.custom-theme.fr-desktop .fr-command.fr-active.fr-selected {
  color: #1e88e5;
  background: #d6d6d6;
}
.custom-theme.fr-toolbar.fr-mobile .fr-command.fr-blink,
.custom-theme.fr-popup.fr-mobile .fr-command.fr-blink {
  background: transparent;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu {
  right: auto;
  bottom: auto;
  height: auto;
  border-radius: 0 0 0px 0px;
  -moz-border-radius: 0 0 0px 0px;
  -webkit-border-radius: 0 0 0px 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper {
  background: #ffffff;
  padding: 0;
  margin: auto;
  -webkit-transition: max-height 0.2s ease 0s;
  -moz-transition: max-height 0.2s ease 0s;
  -ms-transition: max-height 0.2s ease 0s;
  -o-transition: max-height 0.2s ease 0s;
  margin-top: 0;
  max-height: 0;
  height: 0;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content {
  overflow: auto;
  max-height: 275px;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
  margin: 0;
  padding: 0;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li {
  padding: 0;
  margin: 0;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
  color: inherit;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
  background: #d6d6d6;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-disabled {
  color: #bdbdbd;
}
.custom-theme .fr-command.fr-btn.fr-active + .fr-dropdown-menu {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
}
.custom-theme .fr-command.fr-btn.fr-active + .fr-dropdown-menu .fr-dropdown-wrapper {
  height: auto;
  max-height: 275px;
}
.custom-theme .fr-bottom > .fr-command.fr-btn + .fr-dropdown-menu {
  border-radius: 0px 0px 0 0;
  -moz-border-radius: 0px 0px 0 0;
  -webkit-border-radius: 0px 0px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16), 0 -2px 2px 1px rgba(0, 0, 0, 0.14);
}
.custom-theme.fr-modal {
  color: #333333;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.custom-theme.fr-modal .fr-modal-wrapper {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  margin: 20px auto;
  background: #ffffff;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.19), 0 4px 3px 1px rgba(0, 0, 0, 0.14);
  border: 0px;
  border-top: 1px solid #e6e6e6;
}
@media (min-width: 768px) and (max-width: 991px) {
  .custom-theme.fr-modal .fr-modal-wrapper {
    margin: 30px auto;
  }
}
@media (min-width: 992px) {
  .custom-theme.fr-modal .fr-modal-wrapper {
    margin: 50px auto;
  }
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-head {
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  border-bottom: 0px;
  -webkit-transition: height 0.2s ease 0s;
  -moz-transition: height 0.2s ease 0s;
  -ms-transition: height 0.2s ease 0s;
  -o-transition: height 0.2s ease 0s;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-head i {
  color: #333333;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-head i.fr-modal-close {
  top: 0;
  right: 0;
  -webkit-transition: color 0.2s ease 0s;
  -moz-transition: color 0.2s ease 0s;
  -ms-transition: color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-head h4 {
  margin: 0;
  font-weight: 400;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper .fr-modal-head i:hover {
  background: #ebebeb;
}
.custom-theme.fr-overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
}
.custom-theme.fr-popup {
  color: #333333;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-family: Arial, Helvetica, sans-serif;
  border: 0px;
  border-top: 1px solid #e6e6e6;
}
.custom-theme.fr-popup .fr-input-focus {
  background: #f5f5f5;
}
.custom-theme.fr-popup.fr-above {
  border-top: 0;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
}
.custom-theme.fr-popup .fr-input-line {
  padding: 8px 0;
}
.custom-theme.fr-popup .fr-input-line input[type="text"],
.custom-theme.fr-popup .fr-input-line textarea {
  margin: 0px 0 1px 0;
  border-bottom: solid 1px #bdbdbd;
  color: #333333;
}
.custom-theme.fr-popup .fr-input-line input[type="text"]:focus,
.custom-theme.fr-popup .fr-input-line textarea:focus {
  border-bottom: solid 2px #1e88e5;
}
.custom-theme.fr-popup .fr-input-line input + label,
.custom-theme.fr-popup .fr-input-line textarea + label {
  top: 0;
  left: 0;
  -webkit-transition: color 0.2s ease 0s;
  -moz-transition: color 0.2s ease 0s;
  -ms-transition: color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s;
  background: #ffffff;
}
.custom-theme.fr-popup .fr-input-line input.fr-not-empty:focus + label,
.custom-theme.fr-popup .fr-input-line textarea.fr-not-empty:focus + label {
  color: #1e88e5;
}
.custom-theme.fr-popup .fr-input-line input.fr-not-empty + label,
.custom-theme.fr-popup .fr-input-line textarea.fr-not-empty + label {
  color: #808080;
}
.custom-theme.fr-popup .fr-buttons {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  padding: 0 2px;
  line-height: 0;
  border-bottom: 0px;
}
.custom-theme.fr-popup .fr-layer {
  width: 225px;
}
@media (min-width: 768px) {
  .custom-theme.fr-popup .fr-layer {
    width: 300px;
  }
}
.custom-theme.fr-popup .fr-action-buttons button.fr-command {
  color: #1e88e5;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
}
.custom-theme.fr-popup .fr-action-buttons button.fr-command:hover,
.custom-theme.fr-popup .fr-action-buttons button.fr-command:focus {
  background: #ebebeb;
  color: #1e88e5;
}
.custom-theme.fr-popup .fr-action-buttons button.fr-command:active {
  background: #d6d6d6;
  color: #1e88e5;
}
.custom-theme.fr-popup .fr-action-buttons button::-moz-focus-inner {
  border: 0;
}
.custom-theme.fr-popup .fr-checkbox span {
  border: solid 1px #333333;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
}
.custom-theme.fr-popup .fr-checkbox input {
  margin: 0;
  padding: 0;
}
.custom-theme.fr-popup .fr-checkbox input:checked + span {
  background: #1e88e5;
  border-color: #1e88e5;
}
.custom-theme.fr-popup .fr-checkbox input:focus + span {
  border-color: #1e88e5;
}
.custom-theme.fr-popup.fr-rtl .fr-input-line input + label,
.custom-theme.fr-popup.fr-rtl .fr-input-line textarea + label {
  left: auto;
  right: 0;
}
.custom-theme.fr-popup .fr-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #e6e6e6;
  top: -9px;
  margin-left: -5px;
}
.custom-theme.fr-popup.fr-above .fr-arrow {
  top: auto;
  bottom: -9px;
  border-bottom: 0;
  border-top: 5px solid #e6e6e6;
}
.custom-theme.fr-toolbar {
  color: #333333;
  background: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 2px;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  border: 0px;
  border-top: 1px solid #e6e6e6;
}
.custom-theme.fr-toolbar.fr-inline .fr-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #e6e6e6;
  top: -9px;
  margin-left: -5px;
}
.custom-theme.fr-toolbar.fr-inline.fr-above {
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid #e6e6e6;
  border-top: 0;
}
.custom-theme.fr-toolbar.fr-inline.fr-above .fr-arrow {
  top: auto;
  bottom: -9px;
  border-bottom: 0;
  border-top-color: inherit;
  border-top-width: 5px;
}
.custom-theme.fr-toolbar.fr-top {
  top: 0;
  border-radius: 0px 0px 0 0;
  -moz-border-radius: 0px 0px 0 0;
  -webkit-border-radius: 0px 0px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.custom-theme.fr-toolbar.fr-bottom {
  bottom: 0;
  border-radius: 0 0 0px 0px;
  -moz-border-radius: 0 0 0px 0px;
  -webkit-border-radius: 0 0 0px 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.custom-theme .fr-separator {
  background: #ebebeb;
}
.custom-theme .fr-separator.fr-vs {
  height: 34px;
  width: 1px;
  margin: 2px;
}
.custom-theme .fr-separator.fr-hs {
  height: 1px;
  width: calc(96%);
  margin: 0 2px;
}

.ReactCrop--crop-wrapper{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
}

