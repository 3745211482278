.kb_dropdown {
    display: inline-block;
    position: relative;
}

.kb_dropdown__content {
    display: none;
    position: absolute;
    z-index: 1001;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    left: 0;
	/*transform: translateX(-50%);*/
	border-radius: 4px;
	overflow: hidden;
}
.kb_dropdown__content .add-staff-component {
	border-bottom: 1px solid #e8ecf3;
}
.dropdown-xcenter{
  left: 50%;
  transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.kb_dropdown--active .kb_dropdown__content {
    display: block;
}
.kb_dropdown--active .task-status-title{
  border: 1px solid #CCC !important;
}
.kb_dropdown__trigger .task-status-title,.nav-section-action-bar .task-status-title{
  padding: 8px 10px;
  border: 1px solid #E6E6E6;
  color: #354052;
  font-weight: 500;
  border-radius: 4px;
  background: linear-gradient(180deg,#fff,#f2f4f7);
}
.kb_dropdown__trigger .task-status-title:hover,.nav-section-action-bar .task-status-title:hover {
	background: #fcfcfc;
    background: linear-gradient(180deg,#fcfcfc,#eceff3);
}
