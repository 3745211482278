.KB-select_box{
  display: inline-block;
  cursor: pointer;
  .kb_dropdown__content{
    background-color: #ffffff;

  }
}

.KB-selected_title{
  display: inline-block;
  float: left;
  li{
    margin-right: 10px;
    position: relative;
    >span{
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      background: url("clear.png") no-repeat;
      background-size: 100% 100%;
    }
    &:hover{
      >span{
        display: block;
      }
    }
  }
}





.add-option_btn{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  border: none;
  font-size: 18px;
  background-color: #2ea1f8;
}

.KB-select_input{

}

.single-selection{
  display: inline-block;
  padding: 8px 10px;
  border: 1px solid #e6e6e6;
}

.option-content_list{
  max-height: 240px;
  overflow-y: auto;
  li{
	padding: 10px 15px;
	border-bottom: 1px solid #e8ecf3;
	&:last-child {
		border-bottom: none;
	}
    &:hover{
		background-color: #f2f4f7;
		color: #2ea1f8;
		text-decoration: none;
    }
  }
  .option-img{
    display: inline-block;
    margin-right: 5px;
  }
}
