/**
 * React Tipsy
 * ===========
 */
.Tipsy {
  display: block;
  position: absolute;
  z-index: 107000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.Tipsy.in {
  opacity: 1;
  filter: alpha(opacity=100);
}
.Tipsy.top {
  margin-top: -3px;
  padding: 5px 0;
}
.Tipsy.right {
  margin-left: 3px;
  padding: 0 5px;
}
.Tipsy.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.Tipsy.left {
  margin-left: -3px;
  padding: 0 5px;
}
.Tipsy-inner {
  background-color: #3A3939;
  border-radius: 4px;
  color: #FFFFFF;
  /*max-width: 200px;*/
  padding: 3px 8px;
  text-align: center;
  word-break: break-all;
}
.Tipsy-arrow {
  border-color: transparent;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}
.Tipsy.top .Tipsy-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #3A3939;
}
.Tipsy.right .Tipsy-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #3A3939;
}
.Tipsy.left .Tipsy-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #3A3939;
}
.Tipsy.bottom .Tipsy-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #3A3939;
}
